import React from "react"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import Seo from "../components/seo"
import Cloud from "../components/cloud"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(20),
  },
}))

const InteriorPage = () => {
  const classes = useStyles()

  return (
    <Layout bgColor="#ffdd00">
      <Seo title="Zobacz wnętrze" />
      <Box className={classes.root}>
        <Box position="relative">
          <Cloud position="left" />
          <Container maxWidth="md">
            <iframe
              title="Zobacz wnętrze"
              src="https://www.google.com/maps/embed?pb=!1m0!3m2!1spl!2spl!4v1449776755716!6m8!1m7!1sx8QQ3UFzdyYAAAQYJ4g0Dw!2m2!1d52.0786619202358!2d21.00932451727181!3f299.12574741206726!4f-11.381876376635418!5f0.7820865974627469"
              width="100%"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
            ></iframe>
          </Container>
        </Box>
        <Box position="relative">
          <Cloud position="right" />
        </Box>
      </Box>
    </Layout>
  )
}

export default InteriorPage
